import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_END_POINT_Dev, API_END_POINT_Live } from './global';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { generalModel, icons, menu, BtnText, permission, tabArray, optionArray } from '../shared/models/general.model';
import { Subject, BehaviorSubject } from 'rxjs';
import { apiUrl } from '../global/global';
import { DataSharingService } from '../services/data.share.service'

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  stripAddAccount = new BehaviorSubject(null);
  apiEndpoint: string = '';
  showLoader: boolean = true;
  icon = icons;
  menu = menu;
  btnText = BtnText;
  generalModel = new generalModel();
  Permission = permission;
  server: string = 'dev';
  public _albums = [];
  abc: any;

  constructor(private dataSharingService: DataSharingService, private http: HttpClient, private router: Router, private _lightbox: Lightbox) {
    if (localStorage.getItem('server')) {
      this.server = localStorage.getItem('server');
    }
    if (this.server == 'dev') {
      this.apiEndpoint = API_END_POINT_Dev;
    } else if (this.server == 'live') {
      this.apiEndpoint = API_END_POINT_Live;
    }

  }

  getData(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }

  postData(url, value) {
    return this.http.post<any>(this.apiEndpoint + url, value);
  }
  putData(url, value) {
    return this.http.put<any>(this.apiEndpoint + url, value);
  }
  postNewData(url, key, value) {
    return this.http.post<any>(this.apiEndpoint + url, key, value);
  }

  postParams(url, param) {
    return this.http.post<any>(this.apiEndpoint + url, { params: param });
  }

  showAlert(title: string, text: string) {
    swal({
      title: title,
      text: text,
      type: 'success',
      showConfirmButton: false,
      timer: 1500
    })
  }

  errorAlert(text, status) {
    if (text != "Bad token") {
      console.log("text")
      swal({
        type: 'error',
        title: 'Oops...',
        text: text,
        timer: 2500
      }).then((result) => {
        if (status) {
          // alert();

          // localStorage.removeItem('adminToken');
          // this.router.navigate(['/index']);

        }
      })
    } else {

      swal({
        type: 'error',
        text: "Your Session is Expired",
      }).then((result) => {
        if (status) {
          // this.modalRef.hide();
          localStorage.removeItem('username');
          localStorage.removeItem('Addressline2');
          localStorage.removeItem('addressStatus');

          localStorage.removeItem('profilePic');
          localStorage.removeItem('shortNameState');
          localStorage.removeItem('shortNameCountry');
          localStorage.removeItem('userSelectlocation');


          localStorage.removeItem('city');

          localStorage.removeItem('googleAddress');
          localStorage.removeItem('landmarks');
          localStorage.removeItem('Addressline1');
          localStorage.removeItem('pincode');
          localStorage.removeItem('countryCode');
          localStorage.removeItem('phoneNumber');
          localStorage.removeItem('authorization');
          localStorage.removeItem('userID');

          this.router.navigate(['/index']);
          this.dataSharingService.isLoginChanged.next(false);
        }
      })
    }

  }

  toast(title, text) {
    const toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
      background: '#e6e6e6',
    });
    toast({
      type: 'success',
      title: title,
      text: text
    })

  }

  open(image): void {
    // console.log(image);
    this._albums = [];
    const album = {
      src: (image) ? image : ''
    };
    this._albums.push(album);
    // open lightbox
    this._lightbox.open(this._albums, 0, { disableScrolling: true, centerVertically: true });
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }


  // Show and hide Loader
  private loaderSubject = new Subject<Boolean>();
  loaderState = this.loaderSubject.asObservable();

  loader(value) { this.loaderSubject.next(value); }

  setdata(data) {
    for (let x of tabArray) {
      let array1 = {}
      for (let y of x.value) {
        array1[optionArray[y].name] = (data[x.name] == undefined || data[x.name][optionArray[y].name] == undefined ? false : data[x.name][optionArray[y].name]);
      }
      permission[x.name] = array1;
    }
    permission['status'] = "updated";
    return true;
  }


  strip(cardData) {
(<any>window).Stripe.card.createToken({
      number: cardData.number,
      cvc: cardData.cvv,
      exp_month: cardData.exp_month,
      exp_year: cardData.exp_year,
      address_zip: 13850,
    }, (status: number, response: any) => {
      if (response.error) {
        this.stripAddAccount.next(response);
        return response;
      } else {
        this.stripAddAccount.next(response);
       
        return response;
      }
    });



    // if (response.error) {
    //   this.admin.toast(response.error.message, '');
    //   setTimeout(() => {
    //     this.showLoader = false;
    //   }, 10);
    //   console.log(response.error.message, "this.cardinvalidError")
    //   return false;

    // } else {
    //   this.paymentId = response.id;
    //   this.bankName = response.card.brand
    //   this.addnewCard();
    //   return false;
    // }
  }



}



