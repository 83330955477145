import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { apiUrl } from '../../../global/global';
import { BuySellerService } from '../../../external/manageDeal/buy-seller.service';
import { Router } from '@angular/router';
import { ImagePathService } from '../../../services/image-path.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
@Component({
  selector: 'app-seller-user',
  templateUrl: './seller-user.component.html',
  styleUrls: ['./seller-user.component.css']
})
export class SellerUserComponent implements OnInit {
  modalRef: BsModalRef;
  sellerList: any = [];
  sellerDetails: any;
  showMoredata = 0;
  showNorecord = 0;
  Data: any = [];
  showMorekey: boolean = false;
  skip = 0;
  sellerId: any;
  sellerShopesCount: any;
  imgUrl: any;
  trackingForm: FormGroup;
  submittedTracking = false;
  showError3 = false;
  trackingId: any;
  imageFile = '';
  sold = '';
  config1 = {
    keyboard: false,
    ignoreBackdropClick: true,
  };
  validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
  imageExtensionError = false;
  constructor(public apiImage: ImagePathService, public api: BuySellerService, private modalService: BsModalService, private router: Router, public formBuilder: FormBuilder) {
    this.trackingForm = this.formBuilder.group({
      tracking: ['', Validators.compose([Validators.required])],
      courier: ['', Validators.compose([Validators.required])],
    })

  }

  ngOnInit() {
    this.sllerDetails();
  }


  /*model */
  openModalWithClass(add: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      add, {
        class: 'modal-lg', animated: true,
      backdrop: 'static',
    }
      //, Object.assign({ }, )
    );
  }
  openModal(add: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      add, this.config1
    );
  }

  sllerDetails() {
    const data = {
      limit: 10,
      skip: this.skip,
    }
    let url = apiUrl._getManagesell;
    this.api.sellerData(url, data).subscribe(async (res) => {
      console.log('details', res.data);
      if (this.showMorekey == true) {
        for (let i = 0; i < res.data.listing.length; i++) {
          this.sellerList.push(res.data.listing[i]);
        }

        this.sellerShopesCount = res.data.count;
        if (this.sellerShopesCount == this.sellerList.length) {
          this.showMoredata = 0;
        } else {
          this.showMoredata = 1;
        }
      }
      else {
        this.sellerList = res.data.listing;
        this.Data = res.data;
        if (res.data.count == 0) {

          this.showNorecord = 1;

        } else {
          this.showNorecord = 0;

        }
        this.loadMore();
      }
      this.showMorekey = false;


    }, err => {
      console.log(err);
    });
  }

  /*Record show more */
  showMore() {
    this.showMorekey = true;
    this.skip = this.Data.listing.length;
    this.sllerDetails();
  }
  /*load more btn show */
  loadMore() {
    if (this.Data.count > 10) {
      this.showMoredata = 1;

    } else {
      this.showMoredata = 0;
    }
  }


  sellerInfo(id, sold, sellers) {
    this.sold = sold;
    if (sold == 1) {
      this.router.navigate(['buyPhone/phoneDetails/' + id]);
    } else {
      this.sellerId = id;
      this.brandDetails()
      this.openModalWithClass(sellers);
      this.getImage();
    }
  }

  brandDetails() {
    const data = {
      id: this.sellerId,
    }
    let url = apiUrl._getProductDetailsById;
    this.api.brandDetails(url, data).subscribe(async (res) => {
      console.log('detail data', res.data);
      this.sellerDetails = res.data;
      // console.log('detail data 2', this.sellerDetails);


    }, err => {
      console.log(err);
    });
  }

  buyerProduct(id) {
    this.modalRef.hide();
    this.router.navigate(['buyPhone/dealDetails/' + id]);
  }
  getImage() {
    const data = {
    }
    let url = apiUrl._getImageurl;
    this.apiImage.imagePath(url, data).subscribe(async (res) => {
      this.imgUrl = res.data.s3Url + (res.data.s3Folders.shipment);

    }, err => {

    });
  }
  uploadDoc(id, popShipment) {
    this.trackingId = id;
    this.modalRef.hide();
    this.imageFile = '';
    this.trackingForm.patchValue({
      tracking: '',
      courier: '',
    });
    this.openModal(popShipment);

  }

  /*disput submit */
  submitTracking(sellers) {

    this.submittedTracking = true;
    if (this.trackingForm.valid) {
      let params = new HttpParams();
      params = params.append('shippingTrackingId', this.trackingForm.controls.tracking.value);
      params = params.append('shippingAgency', this.trackingForm.controls.courier.value);

      if (this.imageFile) {
        params = params.append('attachmentUrl', this.imageFile);
      }


      let url = apiUrl._productTracking;
      this.api.markReceving(url + '/' + this.trackingId, params).subscribe(async (res) => {
        console.log('disputed', res);
        this.modalRef.hide();
        this.sellerInfo(this.sellerId, this.sold, sellers)


      }, err => {
        console.log(err);
      });

    } else {
      this.showError3 = true;
    }

  }
  /*upload image */

  upload(event) {
    if (event.target.files && event.target.files.length) {
      let file = event.target.files[0];
      let fileType = event.target.files[0].type;
      if (!this.validImageTypes.includes(fileType)) {
        this.imageExtensionError = true;
        setTimeout(() => {
          this.imageExtensionError = false;
        }, 5000);
        return false;
      }


      console.log(file);
      this.api.uploadShipment(file).subscribe(
        success => {
          console.log(success);
          this.imageFile = success.data.image;
          console.log(this.imageFile);

          // this.productImages.push({ "thumbnail": success.data.thumbnail, "image": success.data.image });
          // // console.log(this.productImages);   
          // this.fileInput.nativeElement.value = '';
        }
      );
    }
  }
  back(sellers) {
    this.imageFile = '';
    this.trackingForm.patchValue({
      tracking: '',
      courier: '',
    });
    this.modalRef.hide();
    this.sellerInfo(this.sellerId, this.sold, sellers)
  }
  closed(sellers) {
    this.imageFile = '';
    this.trackingForm.patchValue({
      tracking: '',
      courier: '',
    });
    this.modalRef.hide();
    this.sellerInfo(this.sellerId, this.sold, sellers)
  }

}
