import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,private admin:AdminService) { }

  ngOnInit() {
    this.admin.loaderState.subscribe(data=>{
      if(data)
      this.spinner.show();
      else
      this.spinner.hide();
    });
  }

}
