import { Injectable } from '@angular/core';
import {AdminService} from '../../services/admin.service';
@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(public api: AdminService) { }

  brandData(url,value){
    return this.api.getData(url, value);
  }

}
