import { Injectable } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';
import { apiUrl } from '../../global/global';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  stripBackAccount = new BehaviorSubject(null);
  bankRes: any;
  constructor(public api: AdminService) { }

  getData(url, value) {
    return this.api.getData(url, value);
  }

  getDetails(url, value) {
    return this.api.getData(url, value);
  }

  filterData(url, value) {
    return this.api.getData(url, value);
  }


  getCard(url, value) {
    return this.api.getData(url, value);
  }

  getCardDetails(url, value) {
    return this.api.putData(url, value);
  }
  addDeal(url, value) {
    return this.api.postData(url, value);
  }
  deleteDetails(url, value) {
    return this.api.putData(url, value);
  }

  postData(url, value) {
    return this.api.postData(url, value);
  }
  toast(title, text) {
    const toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
      background: '#e6e6e6',
    });
    toast({
      type: 'error',
      title: title,
      text: text
    })
  }
  abc() {
    // let abc =  await this.bankRes;
    // console.log(abc, "abc===")
    // return abc;

    setTimeout(() => {
      let abc = this.bankRes;
      console.log(abc, "=====")
      return abc;
    }, 1500);
  }

  uploadImage(file) {
    const fd = new FormData();
    if (file.length) {
      for (let x of file) {
        fd.append('image', x);
      }
    }
    else
      fd.append('image', file);
    fd.append('type', "documents");
    return this.api.postData(apiUrl._uploadImage, fd);
  }
}
