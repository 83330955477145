import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
   currDiv: string = 'buyingFaq';
   ShowDiv(divVal: string) {
    this.currDiv = divVal;
  };
  constructor() { }

  ngOnInit() {
  }

}
