import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { DataSharingService } from './services/data.share.service'
// import { MessagingService } from "./shared/messaging.service";
import { ApiSeviceService } from './api-sevice.service';
import { MessagingService } from './services/messaging.service';
import { apiUrl } from './global/global';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  message;
  lat: any;
  lng: any;

  private wasInside = false;

  text = "";

  @Input() makeDifferent;

  @HostListener('click')
  clickInside() {
    this.text = "clicked inside";
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.text = "clicked outside";
    }
    this.wasInside = false;
  }
  constructor(private router: Router, private messagingService:MessagingService, public api: ApiSeviceService, private dataSharingService: DataSharingService,) {
    // this.api.getPosition().then(pos=>
    //   {
    //     console.log(`Positon: ${pos.lat} ${pos.lng}`);
    //   });

  }

  ngOnInit() {
    this.messagingService.requestPermission()
    // this.homeDetails();

  }
  onActivate(event) {
    window.scroll(0,0);  
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
 
}

  ngAfterViewInit() {
    // let loader = this.renderer.selectRootElement('#loader');
    // loader.style.display = "none";
  }
  hasRoute(route: string) {
    return this.router.url.includes(route);
  }

  // homeDetails() {
  //   const data = {
  //     deviceType: 'WEB',
  //     deviceToken: '123456',
  //     deviceIdentifier: '192.168.18.67'
  //   }
  //   let url = apiUrl._accessTokenLogind;
  //   this.api.homeData(url, data).subscribe(async (res) => { 

  //     this.dataSharingService.isUser.next(true);    

  //   }, err => {
  //     console.log(err);
  //   });
  // }




}
