import { Injectable } from '@angular/core';
import {AdminService} from '../../services/admin.service';
import { apiUrl } from '../../global/global';

@Injectable({
  providedIn: 'root'
})
export class BuySellerService {

  constructor(public api: AdminService) {


   }
  
  sellerData(url,value){
    return this.api.getData(url, value);
  }

  buyerData(url,value){
    return this.api.getData(url, value);
  }

  brandDetails(url,value){
    return this.api.getData(url, value);
  }
  
  disputeDetails(url,value){
    return this.api.getData(url, value);
  }
  productdisputes(url,value){
    return this.api.postData(url, value);
  }
  markReceving(url,value){
    return this.api.putData(url, value);
  } 

  productRating(url,value){
    return this.api.postData(url, value);
  } 

  uploadShipment(file) {
    const fd = new FormData();
  
    if(file.length)
    {
        for(let x of file)
        {
            fd.append('image', x );
        }    
    }
    else
    fd.append('image', file );
    fd.append('type', "shipment" );
    return this.api.postData(apiUrl._uploadImage, fd );
  }

}
