import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { apiUrl } from '../../../global/global';
import {BuySellerService} from '../../../external/manageDeal/buy-seller.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { RatingModule } from 'ngx-bootstrap/rating';
import {Router} from '@angular/router';
import { ImagePathService } from '../../../services/image-path.service';

@Component({
  selector: 'app-buyer-user',
  templateUrl: './buyer-user.component.html',
  styleUrls: ['./buyer-user.component.css']
})
export class BuyerUserComponent implements OnInit {
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  modalRef4:BsModalRef;

  buyerList:any = [];

  showMoredata =0;
  showNorecord=0;
  Data: any = [];
  showMorekey:boolean = false;
  skip=0;
  sellerId:any;
  buyerShopesCount:any;
  buyerDetail: any;
  disputeListing: any = [];
  DataDispute:any =[];
  disputeForm: FormGroup;
  ratingForm: FormGroup;
  submittedRating =false;
  showError1 = false;
  submitted = false;
  showPop = 1;
  max = 5;
  rate = 1;
  isReadonly = false;
  imgUrl:any;

  confirmSelection(event: KeyboardEvent) {
    if (event.keyCode === 13 || event.key === 'Enter') {
      this.isReadonly = true;
    }
  }
 
  resetStars() {
    this.rate = 1;
    this.isReadonly = false;
  }


  constructor( public apiImage: ImagePathService, private router: Router, private modalService: BsModalService, public api: BuySellerService, public formBuilder: FormBuilder) { 
    this.disputeForm = this.formBuilder.group({
      disputes: ['', Validators.compose([Validators.required])],
      comments: [''],
    })
    this.ratingForm = this.formBuilder.group({
      commentRating: [''],
    })


  }

  ngOnInit() {
    this.buyerDetails();
  }

  /*model */
  openModalWithClass(add: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      add, Object.assign({}, { class: 'modal-lg' })
    );
  }
 /*model dispute*/
  openModal2(dispute: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(dispute, { class: 'second' });
    this.disputeList();
  }
   /*model item received */
  openModal3(item: TemplateRef<any>) {
    this.modalRef3 = this.modalService.show(item, { class: 'modal-sm' });
  }

     /*model item received */
     openModal4(rating: TemplateRef<any>) {
      this.modalRef4 = this.modalService.show(rating, { });
      this.resetStars();
    }
  


 buyerDetails() {
    const data = {
      limit:10,
      skip:this.skip,
    }
    let url = apiUrl._getManagebuyer;
    this.api.buyerData(url, data).subscribe(async (res) => {
      console.log("check", res.data);
 
      if(this.showMorekey == true){
        for(let i=0; i<res.data.listing.length;i++)
        {
        this.buyerList.push(res.data.listing[i]); 
        }

        this.buyerShopesCount = res.data.count;
        if(this.buyerShopesCount == this.buyerList.length)
        { 
          this.showMoredata =0;        
        }else{
          this.showMoredata =1;
        }
      } 
      else{ 
        this.buyerList=res.data.listing;
        this.Data = res.data;
        if(res.data.count == 0){

          this.showNorecord =1;
      
        }else{
          this.showNorecord =0;
      
        }
        this.loadMore();
      }
      this.showMorekey=false;


    }, err => {
      console.log(err);
    });
  }

/*Record show more */
showMore(){
this.showMorekey = true;
this.skip =this.Data.listing.length;
this.buyerDetails(); 
}
/*load more btn show */
  loadMore(){
    if(this.Data.count > 10){
    this.showMoredata =1;
  
    }else{
    this.showMoredata =0;
    }
  }

  buyerInfo(id, buyers){
      this.sellerId= id;
      this.brandDetails();
      this.openModalWithClass(buyers);

  }


  brandDetails() {
    const data = {
      id : this.sellerId,
    }
    let url = apiUrl._getProductDetailsById;
    this.api.brandDetails(url, data).subscribe(async (res) => {
      console.log('deta', res.data);
      console.log('deta', res.data.product_transaction.transaction_buyer);
      if(res.data.product_transaction.transaction_buyer ==  undefined){
        this.showPop = 0;
      }else{
        this.showPop = 1;
      }
      //this.imgUrl = 'https://edee-dev.s3.amazonaws.com/shipment/';
      this.imgUrl = 'https://edee-prod.s3.us-east-2.amazonaws.com/shipment/';
      //this.buyerProduct(res.data?.id)
      this.buyerDetail = res.data;
      console.log(this.buyerDetail, "this.buyerDetail")
      this.DataDispute = res.data;
    
  
    }, err => {
      console.log(err);
    });
  }


/**disput list **/
  disputeList() {
    const data = {
      limit:5,
      skip:0,
    }
    let url = apiUrl._getDisputes;
    this.api.disputeDetails(url, data).subscribe(async (res) => {
      console.log('detail data', res.data);
      this.disputeListing= res.data.listing;
 
  
    }, err => {
      console.log(err);
    });
  }
/*disput submit */
  submitDispute() {

    this.submitted = true;
    if (this.disputeForm.valid) {
      const data = {
        productId: this.DataDispute.product_transaction.productId,
        disputeId: this.disputeForm.controls.disputes.value,
        sellerUserId:this.DataDispute.userId,
        text: this.disputeForm.controls.comments.value,
      }
      let url = apiUrl._productdispute;
      this.api.productdisputes(url, data).subscribe(async (res) => {
        console.log('disputed', res.data);
      
        this.modalRef2.hide();
        this.buyerDetails();

      }, err => {
        console.log(err);
      });

    }else {
      this.showError1 = true;
    }

  } 

/*mark as recevied */

markRecevied(rating) {

    const data = {
     productId: this.DataDispute.product_transaction.productId,
    }
    let url = apiUrl._markRecevied;
    this.api.markReceving(url, data).subscribe(async (res) => {
      console.log('disputed', res.data);
      this.openModal4(rating);
      this.modalRef3.hide();

    }, err => {
      console.log(err);
    });

} 

/*mark as recevied */



submitRating() {

  this.submittedRating = true;
  if (this.ratingForm.valid) {

    const data = {
      rating:this.rate,
      comment:this.ratingForm.controls.commentRating.value,
      productId: this.DataDispute.product_transaction.productId,
      userId:this.DataDispute.userId,
    }

    let url = apiUrl._productRatings;
    this.api.productRating(url, data).subscribe(async (res) => {
      this.modalRef4.hide();
      this.buyerDetails();
    }, err => {
      console.log(err);
    });

  }else {
    
  }

} 

buyerProduct(id){
  this.modalRef.hide();
    this.router.navigate(['buyPhone/dealDetails/'+id]); 
  }
    getImage() {
      const data = {
      }
      let url = apiUrl._getImageurl;
      this.apiImage.imagePath(url, data).subscribe(async (res) => {
        console.log('res.data::::::',res.data);
        //this.imgUrl = 'https://edee-dev.s3.amazonaws.com/product/';
        this.imgUrl = res.data.s3Url + (res.data.s3Folders.shipment);
  
      }, err => {
  
      });
    }
}
