import { Injectable } from '@angular/core';
import {AdminService} from '../../services/admin.service';
import swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class buyerService {

  constructor(public api: AdminService) { }

  brandData(url,value){
    return this.api.getData(url, value);
  }

  brandDetails(url,value){
    return this.api.getData(url, value);
  }

  filterData(url,value){
    return this.api.getData(url, value);
  }


  getCard(url,value){
    return this.api.getData(url, value);
  }

  getCardDetails(url,value){
    return this.api.putData(url, value);
  }
  addDeal(url,value){
      return this.api.postData(url, value);
  }


  deleteDetails(url,value){
    return this.api.putData(url, value);
  }

  postData(url,value){
    return this.api.postData(url, value);
}
toast(title, text) {
  const toast = swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    
    timer: 5000,
    background: '#e6e6e6',
  });
  toast({
    type: 'error',
    title: title,
    text: text
  })
}
toast1(title, text) {
  const toast = swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    
    timer: 5000,
    background: '#e6e6e6',
  });
  toast({
    type: 'success',
    title: title,
    text: text
  })
}

}
