import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import {AdminService} from './services/admin.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class ApiSeviceService {
  private isUserLogin: boolean = false;
  // public loginUrl: string = 'https://app.bespokechoice.com/api/vendor/login';
  // public baseUrl: string = 'https://app.bespokechoice.com/api/Couple';

  constructor(private http: Http, public api: AdminService) { }

  // setUserLoggedIn(){ this.isUserLogin=true; }

  // unsetUserLoggedIn(){ this.isUserLogin=false; }

  // getUserLoggedIn(){ return this.isUserLogin; }

  // getHeaders(){
  //   var accessToken = localStorage.getItem('accessToken');
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/x-www-form-urlencoded');
  //   headers.append('accessToken', accessToken);
  //   return headers;
    
  // }

  // getHeadersForMultipart(){
  //   var accessToken = localStorage.getItem('accessToken');
  //   var headers = new Headers();
  //   headers.append('accessToken',accessToken);
  //   return headers;
  // }


  // errorHandler(error: Response){
  //   return Observable.throw(error.json() || "Server error");
  // }

  // login
  // adminLogin(email, password){

  //   var headers = this.getHeadersForMultipart();
  //   let input: any = {};
  //   input.email = email;
  //   input.password = password;
  //   input.deviceToken = 'NA';
  //   return this.http.post(this.loginUrl, input)
  //     .map(response => {
  //       // console.log(response);
  //       var r = response.json();
  //       // console.log(r);
  //       localStorage.setItem('accessToken', r.data.authorization);
  //       return r;
  //     })
  //     .catch(this.errorHandler);
  // }

  // logoutApi(){
  //   var headers = this.getHeaders();
  //   return this.http.put(this.baseUrl+'logout', {headers:headers})
  //     .map(response => {response.json();})
  //     .catch(this.errorHandler);
  // }

  // getDataApi(url){
  //   var headers = this.getHeaders();
  //   return this.http.get(this.baseUrl+url, {headers:headers})
  //     .map(response => response.json())
  //     .catch(this.errorHandler);
  // }


  // putDataApi(url, input){
  //   var headers = this.getHeadersForMultipart();
  //   return this.http.put(this.baseUrl+url, input, {headers:headers})
  //     .map(response => response.json())
  //     .catch(this.errorHandler);
  // }

  // postDataApi(url, input){
  //   var headers = this.getHeadersForMultipart();
  //   return this.http.post(this.baseUrl+url, input, {headers:headers})
  //     .map(response => response.json())
  //     .catch(this.errorHandler);
  // }
  homeData(url,value){
    return this.api.putData(url, value);
  }


  // getPosition(): Promise<any>
  // {
  //   return new Promise((resolve, reject) => {

  //     navigator.geolocation.getCurrentPosition(resp => {

  //         resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
  //       },
  //       err => {
  //         reject(err);
  //       });
  //   });

  // }
  
}
