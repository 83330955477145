import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { permission } from '../shared/models/general.model';
import { AdminService } from './admin.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LoginComponent } from 'src/app/external/login/login.component';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  bsModalRef: BsModalRef;
  constructor(private router: Router,private admin:AdminService, private modalService: BsModalService){}
  canActivate()
  {
    let token = localStorage.getItem("authorization");
    if (token) {
      return true;
    }
    localStorage.removeItem('authorization');
    localStorage.removeItem('username');
    localStorage.removeItem('phoneChecks');
    localStorage.removeItem('countryCode');
    localStorage.removeItem('countryName');
    localStorage.removeItem('phoneNumber'); 
    localStorage.removeItem('profilePic');  
    localStorage.removeItem('useremail');  
    localStorage.removeItem('countryWeb');  
    localStorage.removeItem('userID');
    this.showLogin();
  
    return false;
  }
  showLogin() {
    this.bsModalRef = this.modalService.show(LoginComponent, {
      keyboard: false,
      ignoreBackdropClick: true,
      class: "modal-lg login"
    });
    this.bsModalRef.content.modalRef = this.bsModalRef;
  }
}
