export const apiUrl = {

    _getLogin: 'api/user/login',
    _logout:'api/user/logout',
    _getForgot: 'api/user/forgotPassword',
    _getRegister: 'api/user/register',
    _getPhone: 'api/user/updateProfile',
    _getOtp: 'api/user/verifyPhone',
    _getResend: 'api/user/resendOtp',
    _getAllBrands: 'api/product/getAllProducts',    
    _getProductDetailsById: 'api/product/getProductDetailsById',  
    _getBrandData:'api/brands/getAllBrands',
    _getSerialNumber: 'api/product/retrieveSerialNumber', 
    _getPrice: 'api/product/getPrice',
    _getAllShops: 'api/repair_stores/getAllShops',  
    _getgetProductDetailsById: 'api/product/getProductDetailsById',
    _updateProduct: 'api/product/updateProduct/',
    _uploadImage:  'api/upload',
    _addProduct: 'api/product/addProduct',
    _accessTokenLogind: 'api/user/accessTokenLogin',    
    _updateProfiles: 'api/user/updateProfile',
    
    _getpassword: 'api/user/changePassword',
    _getAllFilters:'api/user/getAllFilters',   
    
    _getAllrecycle:'api/recycleStores',
    _getRecycle:'api/recycle_devices',
    _getRecycleDetails:'api/recycle_devices',

    _getRecycleDetail:'api/recycle_devices',
    _getImageurl:'api/user/getS3Folders',
    _getManagesell:'api/deals/selling',
    _getManagebuyer:'api/deals/buying',
    _gettransection:'api/transactions',
    _getChatHistory:'api/chathistory/dialogues',
    _getcardData:'api/paymentmethods',
    _addCardData:'api/paymentmethods',   
    _getcardDetail:'api/product/markAsFeatured',
    _createCardToken:'api/paymentmethods/createCardToken',
    _makepayment:'api/payment/makepayment',
    _directPayment: 'api/shipment/start',
    _getDisputes:'api/disputes',
    _productdispute:'api/productdispute',
    _markRecevied:'api/product/markAsRecieved',
    _productRatings:'api/productrating',
    _productDeals:'api/deals',
    _deleteDetail:'api/product/deleteProduct',
    _updatesEmail:'api/user/sendOtp',
    _updateEmailOtp:'api/user/updateEmailPhone',
    _getCertificates:'api/certificates',
    _deleteCertifed:'api/certificate/delete',

    _getShipingEstimate:'api/shipment/estimate',
    _addNewCard:'api/paymentmethods',

    _getNotification:'api/notifications',
    _updateNotification:'api/notifications',
    _updateCardData:'api/paymentmethods',

    _getBanklist:'api/paymentBanks',
    
    _addBank:'api/addPaymentBanks',
    _upDateBankAccount:'api/paymentBanks',

    _getAllState:'api/states',
    _updatePhoneNo:'api/user/updateEmailPhone',
    _productTracking:'api/transaction/updateShipment',
 
    _getEnvelopes:'api/CourierEnvelopes',  
    _getShipment:'api/shipment/estimate',    
    _markAsSold:'api/product/markAsSold', 
    
    _getNotifiactionsetting:'api/getNotificationSetting',
    _updateNotifiactionsetting:'api/user/updateNotificationSetting',

    _getBankCount:'api/user/countUserBanks',

    _getActiveSubscription:'api/subscription/active',
    _getAllSubscription:'api/subscription/plans',
    _updateSubscription:'api/subscription/cancel',   
    _purchaseSubscription:'api/subscription/purchase',

    _generalSettings:'api/user/getAllGeneralSettings',

   
    
};







