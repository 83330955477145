import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { apiUrl } from '../../global/global';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from './account.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.css']
})
export class AddAccountComponent implements OnInit {
  allState: any = [];  
  showDatesError = false;
  showError = false;
  frontClose = false;
  front2Close = false;
  backClose = false;
  back2Close = false;
  frontCloae = false;
  frontImgbase64: any;
  public subscribers: any = {};
  frontImgSrc: any;
  frontImgError = false;
  backImgbase64: any;
  backImgSrc: any;
  backImgError = false;
  secFrontImgbase64: any;
  secFrontImgSrc: any;
  secFrontImgError = false;
  imgError1=false;
  imgError2=false;
  secBackImgbase64: any;
  secBackImgSrc: any;
  secBackImgError = false;
  selectContry: any;
  gender: any;
  dob: any;
  state: any;
  markPrimary = "0";
  maxDate = new Date();
  addbankAccount: FormGroup;
  constructor(public formBuilder: FormBuilder, private _zone: NgZone, private datePipe: DatePipe, public admin: AdminService, private router: Router, public service: AccountService) {
    
   
    this.addbankAccount = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(40)])],
      dob: ['', Validators.compose([Validators.required])],
      gender: ['', Validators.compose([Validators.required])],
      accountNumber: ['', Validators.compose([Validators.required])],
      bankName: ['', Validators.compose([Validators.required])],
      routingNumber: ['', Validators.compose([Validators.required])],
      addressLine: ['', Validators.compose([Validators.required])],
      country: ['', Validators.compose([Validators.required])],
      state: ['', Validators.compose([Validators.required])],
      city: ['', Validators.compose([Validators.required])],
      ssn: ['', Validators.compose([Validators.required])],
      postalCode: ['', Validators.compose([Validators.required])],
    })
  }

  ngOnInit() {
  }

  //select contry
  selectContery(event) {
    this.selectContry = event.target.value;
    this.getAllState(this.selectContry)
    // if (value == 'IN') {
    // }
    // else {
    // }
  }
  selectGender(event) {
    this.gender = event.target.value;

  }
  getAllState(id) {
    let data = {
      countryCode: id.toString()
    }
    let url = apiUrl._getAllState;
    this.service.getData(url, data).subscribe(async (res) => {
      this.allState = [];
      this.allState = res.data.listing;
      console.log('show data', this.allState);

    }, err => {
      console.log(err);
    });
  }
  //select state
  selectState(event) {
    this.state = event.target.value
  }

  onValueChange(e) {
    this.dob = this.datePipe.transform(e, "d-M-yyyy")
    console.log(this.dob, "this.dob");
  }

  uploadFront1(event) {
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.frontImgError = true;
      return;
    }
    if (event.target.files && event.target.files.length) {
      let file = event.target.files[0];
      this.frontClose = true;
      this.frontImgError = false;
      this.frontImgSrc = file;
      const reader = new FileReader();
      reader.onload = e => this.frontImgbase64 = reader.result;
      reader.readAsDataURL(file);
    }
  }
  //remove img
  removeFront1() {
    this.frontImgbase64 = "";
    this.frontImgSrc = "";
    this.frontClose = false;
    this.frontImgError = false;
  }
  uploadBack1(event) {
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.frontImgError = true;
      return;
    }
    if (event.target.files && event.target.files.length) {
      let file = event.target.files[0];
      this.backImgError = false;
      this.backClose = true;
      this.backImgSrc = file;
      const reader = new FileReader();
      reader.onload = e => this.backImgbase64 = reader.result;
      reader.readAsDataURL(file);
    }
  }
  removeback1() {
    this.backImgbase64 = "";
    this.backImgSrc = "";
    this.backClose = false;
    this.frontImgError = false;
  }

  uploadFront2(event) {
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.secFrontImgError = true;
      return;
    }
    if (event.target.files && event.target.files.length) {
      let file = event.target.files[0];
      this.secFrontImgError = false;
      this.front2Close = true;
      this.secFrontImgSrc = file;
      const reader = new FileReader();
      reader.onload = e => this.secFrontImgbase64 = reader.result;
      reader.readAsDataURL(file);
    }
  }
  removeFront2() {
    this.secFrontImgbase64 = "";
    this.secFrontImgSrc = "";
    this.front2Close = false;
    this.secFrontImgError = false;
  }
  uploadBack2(event) {
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.secFrontImgError = true;
      return;
    }
    if (event.target.files && event.target.files.length) {
      let file = event.target.files[0];
      this.secBackImgError = false;
      this.secBackImgSrc = file;
      this.back2Close = true;
      const reader = new FileReader();
      reader.onload = e => this.secBackImgbase64 = reader.result;
      reader.readAsDataURL(file);
    }
  }
  removeBack2() {
    this.secBackImgError = false;
    this.secBackImgSrc = "";
    this.back2Close = false;
    this.secBackImgbase64 = ""
  }


  /*number only */
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }



  applybankAccount(form) {

    if (form.valid) {
      if(!this.frontImgSrc){
        this.imgError1=true; 
        setTimeout(() => {
          this.imgError1=false;
        }, 3000);
        return false;  
      }
      if(!this.backImgSrc){
        this.imgError1=true; 
        setTimeout(() => {
          this.imgError1=false;
        }, 3000);
        return false;  
      }
      if(!this.secFrontImgSrc){
        this.imgError2=true; 
        setTimeout(() => {
          this.imgError2=false;
        }, 3000);
        return false;  
      }
      if(!this.secBackImgSrc){
        this.imgError2=true; 
        setTimeout(() => {
          this.imgError2=false;
        }, 3000);
        return false;  
      }

      (<any>window).Stripe.bankAccount.createToken({
        country: this.selectContry.toString(),
        currency: "USD",
        routing_number: form.value.routingNumber,
        account_number: form.value.accountNumber,
        account_holder_name: form.value.name.toString(),
        account_holder_type: "individual"
      }, (status: number, response: any) => {
        this._zone.run(() => {
          if (status === 200) { 
            const bankinfo = new FormData();
            bankinfo.append('bankAccountHolderName', form.value.name.toString());
            bankinfo.append('bankName', form.value.bankName);
            bankinfo.append('lastFourDigits', response.bank_account.last4);
            bankinfo.append('stripeBankId', response.id);
            bankinfo.append('gender', this.gender);
            bankinfo.append('dob', this.dob);
            bankinfo.append('bankRoutingNumber', form.value.routingNumber);
            bankinfo.append('address', form.value.addressLine);
            bankinfo.append('city', form.value.city);
            bankinfo.append('state', this.state);
            bankinfo.append('country', this.selectContry);
            bankinfo.append('postalCode', form.value.postalCode);

            bankinfo.append('idProofFront', this.frontImgSrc);
            bankinfo.append('idProofRear', this.backImgSrc);
            bankinfo.append('idProofImage', this.secFrontImgSrc);
            bankinfo.append('idProofImageBack', this.secBackImgSrc);
            bankinfo.append('idNumber', form.value.ssn);
            console.log(bankinfo, "bankinfo===")
            let url = apiUrl._addBank;
            this.admin.postData(url, bankinfo).subscribe(res => {
              this.router.navigate(['/setting/account']);
              localStorage.removeItem('bankAccount');

            }, err => {
              console.log(err);

            });
          } else {
            this.admin.toast(response.error.message, '');
          }
        });
      });


    } else {
      this.showError = true;
    }

  }

}
