import { Component, OnInit, NgZone, ElementRef, ViewChild  } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { apiUrl } from '../../global/global';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.css']
})
export class AddCardComponent implements OnInit {
  showLoader = false;
  expiryYear: any = [];
  addNewCard: FormGroup;
  cardSubmitted = false;
  checked = false;
  showError = false;
  public subscribers: any = {};
  cardReady = false;
  paymentId: any;
  bankName: any;
  plan:any;
  extraData = {
    "name": null,
    "address_city": null,
    "address_line1": null,
    "address_line2": null,
    "address_state": null,
    "address_zip": null,
  };

  @ViewChild('ccNumber') ccNumberField: ElementRef;
  constructor(public formBuilder: FormBuilder, public admin: AdminService, private router: Router, private route: ActivatedRoute, private _zone: NgZone) {
    this.addNewCard = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(40)])],
      cardNo: ['', Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(16)])],
      date: ['', Validators.compose([Validators.required])],
      year: ['', Validators.compose([Validators.required])],
      cvv: ['', Validators.compose([Validators.required])],
    })
    for (let i = 2021; i <= 2099; i++) {
      this.expiryYear.push({ expireYear: i });
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.plan = params['plan'];
    });
  }


 
  /*number only */
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  // add new card====
  applyNewCard(form) {
    if (form.valid) {
      this.checked = true;
      this.showLoader = true;
      (<any>window).Stripe.card.createToken({
        number: form.value.cardNo,
        cvc: form.value.cvv,
        exp_month: form.value.date,
        exp_year: form.value.year,
      }, (status: number, response: any) => {
        this._zone.run(() => {
          if (status === 200) {
            this.paymentId = response.id;
            this.bankName = response.card.brand
            this.addnewCard();
          } else {
            this.admin.toast(response.error.message, '');
          }
        });
      });
    } else {
      this.showLoader = false;
      this.showError = true;
    }
    setTimeout(() => {
      this.showLoader = false;
    }, 1000);

  }

  addnewCard() {
    let lastDigits = this.addNewCard.value.cardNo.substr(this.addNewCard.value.cardNo.length - 4);
    let paymentId = this.paymentId;
    let cardtype = this.bankName;
    const data = {
      //name: form.value.name,           
      cardNumber: lastDigits,
      expireMonth: this.addNewCard.value.date,
      expireYear: this.addNewCard.value.year,
      bankName: cardtype,
      paymentGatewayId: paymentId,
    }
    console.log(data);
    let url = apiUrl._addNewCard;
    this.admin.postData(url, data).subscribe(res => {
      if(this.plan){
        this.router.navigate(['/setting/subcription']);
      }else{
        this.router.navigate(['/setting/card']);
      }
      
    }, err => {
      console.log(err);
      this.showLoader = false;
    });

  }

  creditcardNoSpacing() {
    const input = this.ccNumberField.nativeElement;
    const { selectionStart } = input;
    const { cardNo } = this.addNewCard.controls;

    let trimmedCardNum = cardNo.value.replace(/\s+/g, '');

    if (trimmedCardNum.length > 16) {
      trimmedCardNum = trimmedCardNum.substr(0, 16);
    }

     /* Handle American Express 4-6-5 spacing */
    const partitions = trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37') 
                       ? [4,6,5] 
                       : [4,4,4,4];

    const numbers = [];
    let position = 0;
    partitions.forEach(partition => {
      const part = trimmedCardNum.substr(position, partition);
      if (part) numbers.push(part);
      position += partition;
    })

    cardNo.setValue(numbers.join(' '));

    /* Handle caret position if user edits the number later */
    if (selectionStart < cardNo.value.length - 1) {
      input.setSelectionRange(selectionStart, selectionStart, 'none');
    }
  }



}
