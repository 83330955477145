import {Validators} from '@angular/forms';
import {NoWhitespaceValidator} from '../../services/NoWhiteSpace.validator';
import * as CONSTANT from '../../services/constants';

export class generalModel {
    name: string;
    firstName: string;
    lastName: string;
    fullContactNo: number;
    email;
    iso2: any = 'in';
    countryCode = 91;
    status: string;
    country: string;
    city: string;
    state: string;
}

// {name:'jobs',value:['requested','accepted','completed','cancelledJobs']},

export const menu = [
    {name: 'dashboard', path: 'dashboard', value: []},
    {name: 'buyers', path: 'buyers', value: []},
    {name: 'vendors', path: 'vendors', value: []},
    {name: 'contacts', path: 'contacts', value: []},
    {name: 'offerChecks', path: 'offerChecks', value: []},
    {name: 'salesProgression', path: 'salesProgression', value: []},
    {name: 'notifications', path: 'notifications', value: []},
    {name: 'supports', path: 'supports', value: []},
    {name: 'reports', path: 'reports', value: [] }
];
export const icons = {
    'dashboard': 'property.svg',
    'buyers': 'agents.svg',
    'vendors': 'profile.svg',
    'contacts': 'contact.svg',
    'offerChecks': 'Offer-Checks.svg',
    'salesProgression': 'sale-progress.svg',
    'notifications': 'notification.svg',
    'supports': 'support.svg',
    'reports': 'reports.svg'
};

export const Month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const Week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const BtnText = {
    week: 'Week',
    month: 'Month',
    year: 'Year',
    daily: 'Daily',
    current: 'Today',
    previous: 'Previous',
    next: 'Next'
};

export const tabArray = [
    {label: '', name: 'customer', value: [0, 1, 2, 3, 4]},
    {label: '', name: 'dashboard', value: [0, 1, 2, 4]},
    {label: '', name: 'buyer', value: [0, 1, 2, 4]},
    {label: '', name: 'branch', value: [0, 1, 2, 3, 4]},
    {label: '', name: 'vendor', value: [0, 1, 2,3, 4]},
    {label: '', name: 'contacts', value: [0, 1, 2, 4]},
    {label: '', name: 'offerChecks', value: [0, 1, 2, 4]},
    {label: '', name: 'salesProgression', value: [0, 1, 2, 4]},
    {label: '', name: 'notifications', value: [0, 1, 2]},
    // {label: '', name: 'notifications', value: [0, 1, 2, 3, 4]},
    {label: '', name: 'property', value: [0, 1, 2, 4]},
];
export const optionArray = [
    {name: 'view', value: false},    // 0
    {name: 'add', value: false},    // 1
    {name: 'edit', value: false},    // 2
    {name: 'delete', value: false},    // 3
    {name: 'status', value: false},    // 4
    {name: 'refund', value: false},    // 5
];

export const permission = {status: 'default'};
for (let x of tabArray) {
    let array1 = {};
    for (let y of x.value) {
        array1[optionArray[y].name] = optionArray[y].value;
    }
    permission[x.name] = array1;
}


