import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { ToastrComponentlessModule } from 'ngx-toastr';

import firebase from "firebase/app";
import "firebase/messaging";

@Injectable({
  providedIn: 'root'
})

export class MessagingService {  
  firbasNotification = new BehaviorSubject(null);
  currentMessage = new BehaviorSubject(null);
  constructor(private angularFireMessaging: AngularFireMessaging) {
      console.log('Constructor messaging service');
      this.angularFireMessaging.messages.subscribe(
          (_messaging) => {
              this.firbasNotification.next(_messaging);
              console.log(_messaging, "_messaging------")

          }, error => {
              console.log(error, "error---------")
          }
      );
  }
  requestPermission() {
      console.log('Request Permission111');
      localStorage.setItem("fcmToken", 'WEP_IP');
      this.angularFireMessaging.requestToken.subscribe(
          (token) => {
              let fcmToken = token ? (token) : 'WEP_IP';
              localStorage.setItem("fcmToken", fcmToken);
              console.log(token, "token-------")
          },
          (err) => {
              localStorage.setItem("fcmToken", "WEP_IP");
              console.error('Unable to get permission to notify.', err);
          }
      );
  }
  receiveMessage() {
    console.log("messages");
      this.angularFireMessaging.messages.subscribe(
          (payload) => {
              console.log("new message received. ", payload);
              this.firbasNotification.next(payload);
              this.currentMessage.next(payload);
          }, error => {
              console.log(error, "error---------")
          })
  }


}
