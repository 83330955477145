import { Injectable } from '@angular/core';
import {AdminService} from '../services/admin.service';
@Injectable({
  providedIn: 'root'
})
export class ImagePathService {

  constructor(public api: AdminService) { 

    
  }
  imagePath(url,value){
    return this.api.getData(url, value);
  }

}
