import { Component, OnInit,OnDestroy } from '@angular/core';
import { apiUrl } from '../../global/global';
import { DataSharingService } from '../../services/data.share.service'
import { HomeService } from './home.service';
import { ImagePathService } from '../../services/image-path.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit,OnDestroy {
  slidesbanner = [
    { title: 'Free Diagnose Every-time', detail: 'You can diagnose your device n number of times and check your device health and get the best price in the market.' },
    { title: 'Recycle Your Device', detail: 'More smartphones, more e-waste. There has never been a more critical time to use our tech responsibly and dispose too the same way.' },
    { title: 'Buy or Sell Anytime', detail: 'Buy and Sell certified devices. Get maximum value for your device.' }
  ];
  imgUrl: any;
  slidesTestimonail = ['', '', ''];
  slidesAlldeals: any = [];
  slidesTopdeals: any = [];
  slidesHotdeals: any = [];
  slidesRecentlyadd: any = [];
  slidesNearby: any = [];
  searchLocation = {} as any;
  searchData: any;
  mapLatitude = 40.730610;
  mapLongitude = -73.935242;

  constructor(public apiImage: ImagePathService, private dataSharingService: DataSharingService, public api: HomeService,) {



  }
  ngOnInit() {  
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        let geocoder = new google.maps.Geocoder;
        let latlng = { lat: +pos.coords.latitude, lng: +pos.coords.longitude };
        geocoder.geocode({ 'location': latlng }, function (results) {
          console.log("resultsss", results);
          if (results[0]) {
            localStorage.setItem("currentAddress", results[0].formatted_address);

          } else {
          }
        });

      });
    }
    this.getBrand();
    this.getImage();
    this.dataSharingService.addressUpdate.subscribe(value => {
        this.getBrand();
      
    });

  }
  ngOnDestroy() {
    this.dataSharingService.addressUpdate.next(false);
  }
  /*slider slick*/
  sliderBanner = { "slidesToShow": 1, "dots": true, "slidesToScroll": 1, autoplay: true, infinite: true, autoplaySpeed: 2000, speed: 1500, };
  sliderTestimonail = { "slidesToShow": 1, "dots": true, "slidesToScroll": 1, autoplay: true, infinite: true, autoplaySpeed: 2000, speed: 1500, };

  sliderAlldeals = { "slidesToShow": 5, "slidesToScroll": 1, "variableWidth": true, autoplay: true, infinite: true, autoplaySpeed: 0, speed: 4000, cssEase: 'linear', };
  sliderTopdeals = { "slidesToShow": 5, "slidesToScroll": 1, "variableWidth": true, autoplay: true, infinite: true, autoplaySpeed: 0, speed: 4000, cssEase: 'linear', };
  sliderHotdeals = { "slidesToShow": 5, "slidesToScroll": 1, "variableWidth": true, autoplay: true, infinite: true, autoplaySpeed: 0, speed: 4000, cssEase: 'linear', };
  sliderRecentlyadd = { "slidesToShow": 5, "slidesToScroll": 1, "variableWidth": true, autoplay: true, infinite: true, autoplaySpeed: 0, speed: 4000, cssEase: 'linear', };
  sliderNearby = { "slidesToShow": 5, "slidesToScroll": 1, "variableWidth": true, autoplay: true, infinite: true, autoplaySpeed: 0, speed: 4000, cssEase: 'linear', };

  slickInit(e) {

    // console.log('slick initialized');
  }
  onSelect(i) {
    //alert("check");

  }

  getBrand() {
    // this.mapLatitude = parseFloat(localStorage.getItem('latitude'));
    // this.mapLongitude = parseFloat(localStorage.getItem('longitude'));

    if (localStorage.getItem('latitude') == null && localStorage.getItem('longitude') == null) {
      this.mapLatitude;
      this.mapLongitude;
    } else {
      this.mapLatitude = parseFloat(localStorage.getItem('latitude'));
      this.mapLongitude = parseFloat(localStorage.getItem('longitude'));
    }

    this.searchData = { "latitude": this.mapLatitude.toString(), "longitude": this.mapLongitude.toString() };
    const data = {
      limit: 6,
      paginateType: 'ALL',
      webSpecificLimit: 1,
      searchLocation: JSON.stringify(this.searchData),

    }
    // console.log(data);
    let url = apiUrl._getAllBrands;
    this.api.brandData(url, data).subscribe(async (res) => {
      // console.log(res.data);

      this.slidesAlldeals = res.data.allDeals;
      this.slidesTopdeals = res.data.topDeals;
      this.slidesHotdeals = res.data.hotDeals;
      this.slidesHotdeals = res.data.hotDeals;
      this.slidesRecentlyadd = res.data.recentDeals;
      this.slidesNearby = res.data.nearbyDeals;


    }, err => {
      console.log(err);
    });



  }
  /*get Image url */
  getImage() {
    const data = {
    }
    let url = apiUrl._getImageurl;
    this.apiImage.imagePath(url, data).subscribe(async (res) => {
      this.imgUrl = res.data.s3Url + (res.data.s3Folders.product);

    }, err => {

    });
  }
}
