import { Injectable } from '@angular/core';
import {AdminService} from '../../services/admin.service';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})


export class LoginService {

  constructor(public api: AdminService) { }
  // url ='http://3.11.173.31:3000/'

  

  putData(url,value){
    return this.api.putData(url, value);
  }
  forgotData(url,value){
    return this.api.putData(url, value);
  }
  
  registerData(url,value){
    return this.api.postData(url, value,);
  }

  phoneData(url,value){
    return this.api.putData(url, value);
  }  

  otpData(url,value){
    return this.api.putData(url, value);
  } 
  otpResend(url,value){
    return this.api.putData(url, value);
  } 
  locationData(url,value){
    return this.api.putData(url, value);
  } 
  
  

  showAlert(title:string,text:string)
  {
    swal({
      title:title,
      text:text,
      type:'error',
      showConfirmButton: false,
      timer: 1500
    })
  }



  toast(title,text)
  {
    const toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      background: '#e6e6e6',

    });
      toast({
      type: 'error',
      title: title,
      text:text
    })

  }  
  toast1(title,text)
  {
    const toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      background: '#e6e6e6',

    });
      toast({
      type: 'success',
      title: title,
      text:text
    })

  }  
  


}
